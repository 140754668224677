import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 50,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg className={classes.svg} viewBox="0 0 523 164" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={classes.path} d="M177.741 88.4547C172.835 86.9614 168.953 84.98 166.095 82.5107L170.48 72.6667C173.273 74.876 176.701 76.6787 180.763 78.076C184.823 79.4734 188.9 80.1707 192.993 80.1707C198.061 80.1707 201.847 79.36 204.348 77.7347C206.848 76.112 208.1 73.968 208.1 71.3027C208.1 69.3533 207.401 67.7453 206.004 66.4773C204.607 65.2107 202.837 64.2213 200.693 63.5053C198.549 62.7907 195.625 61.98 191.921 61.0693C186.723 59.836 182.517 58.6 179.3 57.3654C176.084 56.132 173.321 54.1973 171.016 51.5667C168.709 48.9347 167.556 45.3773 167.556 40.8947C167.556 37.1267 168.58 33.6987 170.627 30.612C172.673 27.5267 175.759 25.0734 179.885 23.2547C184.011 21.436 189.061 20.5253 195.04 20.5253C199.197 20.5253 203.291 21.0454 207.32 22.084C211.348 23.124 214.825 24.6187 217.749 26.568L213.752 36.4107C210.764 34.6573 207.645 33.3254 204.397 32.4147C201.147 31.5054 197.996 31.0507 194.943 31.0507C189.939 31.0507 186.22 31.896 183.784 33.584C181.347 35.2747 180.128 37.516 180.128 40.3094C180.128 42.2587 180.827 43.852 182.224 45.0854C183.621 46.32 185.392 47.2947 187.536 48.0094C189.68 48.724 192.604 49.536 196.308 50.4454C201.375 51.6147 205.532 52.8333 208.783 54.1C212.031 55.3667 214.808 57.3014 217.116 59.8987C219.421 62.4987 220.575 66.008 220.575 70.4253C220.575 74.1933 219.552 77.6053 217.505 80.6587C215.459 83.7133 212.355 86.1507 208.197 87.968C204.039 89.7867 198.971 90.6973 192.993 90.6973C187.731 90.6973 182.647 89.9494 177.741 88.4547Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M288.797 55.8053C291.656 53.4667 293.085 50.1213 293.085 45.7667C293.085 41.4147 291.656 38.068 288.797 35.7294C285.937 33.3894 281.747 32.2201 276.225 32.2201H261.411V59.3147H276.225C281.747 59.3147 285.937 58.1453 288.797 55.8053ZM292.257 24.424C296.643 26.3734 300.005 29.1667 302.344 32.8053C304.684 36.4453 305.853 40.764 305.853 45.7667C305.853 50.7067 304.684 55.0107 302.344 58.6814C300.005 62.352 296.643 65.1627 292.257 67.112C287.872 69.0614 282.721 70.0347 276.809 70.0347H261.411V89.7227H248.741V21.5H276.809C282.721 21.5 287.872 22.4747 292.257 24.424Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M334.117 21.5H346.787V89.7227H334.117V21.5Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M420.856 35.7294C417.997 33.3894 413.805 32.2201 408.284 32.2201H393.469V59.412H408.284C413.805 59.412 417.997 58.2267 420.856 55.8547C423.715 53.484 425.144 50.1213 425.144 45.7667C425.144 41.4147 423.715 38.068 420.856 35.7294ZM425.437 89.7227L411.5 69.7427C410.916 69.808 410.039 69.84 408.869 69.84H393.469V89.7227H380.8V21.5H408.869C414.78 21.5 419.931 22.4747 424.316 24.424C428.703 26.3734 432.064 29.1667 434.404 32.8053C436.743 36.4453 437.912 40.764 437.912 45.7667C437.912 50.9013 436.66 55.3187 434.16 59.0227C431.657 62.7254 428.068 65.488 423.391 67.3067L439.081 89.7227H425.437Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M496.095 65.6493V89.7227H483.425V65.844L456.624 21.5H470.171L490.151 54.7347L510.324 21.5H522.8L496.095 65.6493Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M179.915 127.869C181.019 126.964 181.572 125.671 181.572 123.992C181.572 122.313 181.019 121.027 179.915 120.132C178.809 119.237 177.208 118.789 175.109 118.789H169.476V129.228H175.109C177.208 129.228 178.809 128.775 179.915 127.869ZM181.671 139.103L176.667 131.979C176.048 132.023 175.561 132.044 175.208 132.044H169.476V139.103H166.163V115.907H175.208C178.213 115.907 180.577 116.625 182.3 118.061C184.023 119.497 184.885 121.473 184.885 123.992C184.885 125.781 184.443 127.305 183.56 128.565C182.676 129.824 181.416 130.741 179.783 131.315L185.283 139.103H181.671Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M230.281 136.22V139.103H213.448V115.907H229.817V118.789H216.763V125.915H228.393V128.731H216.763V136.22H230.281Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M260.552 138.556C258.928 138.015 257.653 137.313 256.725 136.452L257.952 133.867C258.835 134.663 259.967 135.308 261.348 135.805C262.728 136.303 264.148 136.551 265.605 136.551C267.528 136.551 268.964 136.225 269.913 135.573C270.863 134.923 271.339 134.055 271.339 132.972C271.339 132.177 271.079 131.531 270.559 131.033C270.04 130.536 269.4 130.156 268.637 129.891C267.875 129.625 266.799 129.327 265.407 128.996C263.661 128.576 262.253 128.156 261.183 127.736C260.111 127.317 259.193 126.671 258.431 125.799C257.669 124.925 257.288 123.749 257.288 122.269C257.288 121.032 257.613 119.916 258.267 118.923C258.917 117.928 259.905 117.132 261.232 116.536C262.557 115.94 264.203 115.641 266.169 115.641C267.539 115.641 268.887 115.819 270.212 116.172C271.537 116.525 272.675 117.033 273.625 117.696L272.531 120.347C271.559 119.729 270.521 119.26 269.416 118.939C268.312 118.619 267.229 118.459 266.169 118.459C264.292 118.459 262.883 118.801 261.944 119.485C261.005 120.171 260.536 121.055 260.536 122.136C260.536 122.932 260.8 123.577 261.331 124.075C261.861 124.572 262.519 124.959 263.303 125.235C264.087 125.511 265.152 125.804 266.501 126.113C268.245 126.533 269.648 126.953 270.709 127.372C271.769 127.792 272.68 128.432 273.443 129.295C274.205 130.156 274.585 131.315 274.585 132.773C274.585 133.988 274.255 135.099 273.592 136.104C272.928 137.109 271.924 137.904 270.576 138.489C269.228 139.075 267.572 139.368 265.605 139.368C263.86 139.368 262.176 139.097 260.552 138.556Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M319.452 136.22V139.103H302.619V115.907H318.988V118.789H305.932V125.915H317.564V128.731H305.932V136.22H319.452Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M361.636 130.653L356.632 119.287L351.628 130.653H361.636ZM362.796 133.304H350.468L347.917 139.103H344.504L355.008 115.907H358.289L368.827 139.103H365.347L362.796 133.304Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M409.22 127.869C410.324 126.964 410.876 125.671 410.876 123.992C410.876 122.313 410.324 121.027 409.22 120.132C408.115 119.237 406.513 118.789 404.415 118.789H398.781V129.228H404.415C406.513 129.228 408.115 128.775 409.22 127.869ZM410.976 139.103L405.972 131.979C405.353 132.023 404.868 132.044 404.515 132.044H398.781V139.103H395.468V115.907H404.515C407.519 115.907 409.883 116.625 411.605 118.061C413.329 119.497 414.189 121.473 414.189 123.992C414.189 125.781 413.748 127.305 412.865 128.565C411.98 129.824 410.721 130.741 409.087 131.315L414.588 139.103H410.976Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M446.845 137.827C444.979 136.799 443.516 135.387 442.456 133.585C441.395 131.785 440.865 129.759 440.865 127.505C440.865 125.251 441.395 123.225 442.456 121.424C443.516 119.624 444.984 118.209 446.863 117.183C448.74 116.156 450.839 115.641 453.159 115.641C454.969 115.641 456.627 115.945 458.129 116.553C459.631 117.161 460.913 118.061 461.973 119.253L459.819 121.341C458.073 119.508 455.897 118.591 453.291 118.591C451.568 118.591 450.011 118.977 448.619 119.751C447.228 120.524 446.139 121.589 445.355 122.948C444.571 124.307 444.179 125.827 444.179 127.505C444.179 129.184 444.571 130.703 445.355 132.061C446.139 133.42 447.228 134.485 448.619 135.259C450.011 136.032 451.568 136.419 453.291 136.419C455.876 136.419 458.051 135.491 459.819 133.635L461.973 135.723C460.913 136.916 459.625 137.821 458.112 138.44C456.599 139.059 454.937 139.368 453.125 139.368C450.807 139.368 448.712 138.853 446.845 137.827Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M509.855 115.907V139.103H506.541V128.764H493.221V139.103H489.907V115.907H493.221V125.881H506.541V115.907H509.855Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M10.3226 40.8787L81.132 1.30547e-05V41.7761L46.496 61.7654L10.3226 40.8787Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M0 93.6813V51.904L70.8093 92.7919L34.636 113.675L0 93.6813Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M50.9787 121.271L85.6134 101.281L121.787 122.167L50.9787 163.048V121.271Z" fill="#7FF2E2"/>
      <path className={classes.path} d="M61.3013 70.2546L97.4747 49.3707L132.109 69.3653V111.141L61.3013 70.2546Z" fill="#7FF2E2"/>
    </svg>
  );
};

export default LogoFull;
