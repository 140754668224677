import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg className={classes.svg} width="203" height="250" viewBox="0 0 203 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_2)">
        <path className={classes.path} d="M15.8281 62.678L124.4 -0.00194631V64.0533L71.2924 94.7024L15.8281 62.678Z" fill="#7FF2E2"/>
        <path className={classes.path} d="M0 143.639V79.5837L108.571 142.276L53.1065 174.295L0 143.639Z" fill="#7FF2E2"/>
        <path className={classes.path} d="M78.1644 185.941L131.271 155.292L186.735 187.317L78.1644 250V185.941Z" fill="#7FF2E2"/>
        <path className={classes.path} d="M93.9923 107.72L149.458 75.6988L202.564 106.356V170.412L93.9923 107.72Z" fill="#7FF2E2"/>
      </g>
      <defs>
        <clipPath id="clip0_2_2">
          <rect width="202.567" height="250" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoFull;
